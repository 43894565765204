@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800&display=swap");

:root {
  --font-family-title: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-weight-bold: 700;
  --button-padding: 1.5rem 3rem;
  --button-color: var(--color-white);
  --color-primary: #d73953;
  --button-border: 0.2rem solid var(--color-primary);
  --font-size-small: 1.2rem;
  --font-size-medium: 1.4rem;
  --font-size-large: 1.8rem;
  --font-size-x-large: 2.3rem;
  --font-size-xx-large: 4.8rem;
  --font-weight-bold: 700;
  --font-light-grey: #f3f3f3;
}
html {
  --scrollbarBG: #CFD8DC;
  --thumbBG: #000000;
}
body::-webkit-scrollbar {
  width: 11px;
}
body {
  margin: 0;
  padding: 0;
  scrollbar-width:initial;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
.bg-black {
  background-color: #000000;
}
/*Header*/
.title {
  font-size: 40px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
}
.links {
  text-decoration: none;
  list-style: none;
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-weight: 800;
}
.links a {
  display: inline-block;
  position: relative;
  color: white;
  text-decoration: none;
  text-transform: capitalize;
}
.links a:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--color-primary);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.links a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.togbtn{
  color: rgb(255, 255, 255);
  font-size: 45px;
}
header {
  width: 100%;
  height: 80px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 100px;
}
.hamburger {
  display: none;
}
.nav-bar ul {
  display: flex;
}
.nav-bar ul li a {
  display: block;
  color: #fff;
  font-size: 20px;
}

@media only screen and (max-width:1320px) {
  .header {
    padding: 0 50px;
  }
}
@media only screen and (max-width:1100px) {
  .header {
    padding: 0 30px;
  }
  .nav-bar ul li a {
    display: block;
    color: #fff;
    font-size: 20px;
  }
}
@media only screen and (max-width:985px) {
  .hamburger {
    display: block;
    cursor: pointer;
    background-color: #000000;
    border: none;
  }
  .hamburger .lines {
    width: 30px;
    height: 30px;
    color: white;
  }
  .nav-bar {
    height: 0px;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    width: 100vw;
    background: black;
    transition: 0.2s;
    overflow: hidden;
  }
  .nav-bar.active {
    height: 480px;
  }
  .nav-bar ul {
    display: block;
    width: fit-content;
    margin: 80px auto 0 auto;
    text-align: center;
    transition: 0.5s;
    opacity: 0;
  }
  .nav-bar.active ul {
    opacity: 1;
  }
  .nav-bar ul li a {
    margin-bottom: 12px;
    color: white;
  }
}
/*Header end*/
/*Hero section*/
.hero {
  padding: 5rem 0rem 5rem 0rem;
}
.hero h2 {
  color: white;
  font-family: var(--font-family-title);
  font-size: var(--font-size-xx-large);
  font-weight: var(--font-weight-bold);
}
.buttons {
  font-family: var(--font-family-title);
  padding: var(--button-padding);
  background-color: black;
  color: white;
  border: var(--button-border);
  margin-top: 2.5rem;
  transition: all 0.25s ease-in;
  width: 15%;
}
.buttons:hover {
  background-color: var(--color-primary);
  transition: all 0.25s ease-in;
}
@media (max-width:1050px) {
  .hero {
    text-align: center;
  }
  .hero h2 {
    font-size: 2rem;
  }
  .buttons {
    width: 50%;
    padding: 1rem;
  }
}
@media (max-width:420px) {
  .hero {
    align-items: center;
    width: 100%;
  }
  .hero {
    text-align: center;
  }
  .hero h2 {
    font-size: 2rem;
  }
  .buttons {
    width: 50%;
    padding: 1rem;
  }
}
/*Hero section end*/
/*Service section */
.rows {
  display: flex;
  flex-direction: row;
}
@media (max-width:883px) {
  .rows {
    display: flex;
    flex-direction: column;
  }
  .service-list {
    width: 100%;
  }
}
.section-header {
  margin: 52px 0px 52px 0px;
}
.section-title {
  font-family: var(--font-family-title);
  font-weight: 700;
  font-size: var(--font-size-xx-large);
}
.section-tagline {
  text-transform: uppercase;
  color: #d73953;
  font-weight: var(--font-weight-bold);
  font-size: 22px;
}
@media (max-width:780px) {
  .section-title {
    font-family: var(--font-family-title);
    font-weight: 700;
    font-size: 3rem;
  }
  .section-tagline {
    font-size: 1.5rem;
  }
}
.service-list {
  width: 25%;
  padding: 2rem;
  font-family: var(--font-family-title);
  background-color: var(--font-light-grey);
  margin: 6px 7px;
  font-size: 1.6rem;
  font-weight: var(--font-weight-bold);
  color: #551a8b;
}
.service-list:hover {
  color: white;
  background-color: var(--color-primary);
}
/*Service section end */
/*Work section */
.section-body li {
  list-style: none;
}
.images {
  position: relative;
}
.images a {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 2rem;
  text-decoration: none;
  font-family: var(--font-family-title);
  font-weight: var(--font-weight-bold);
  color: white;
}
.overlay {
  position: absolute;
  text-align: center;
  vertical-align: bottom;
  font-size: 2rem;
  padding-top: 50%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: black;
}
.workimg {
  width: 100%;
  height: 500px;
}
.images:hover .overlay {
  cursor: pointer;
  scale: 1;
  color: white;
  opacity: 0.5;
}
.images:hover {
  scale: 0.9;
  transition: all 0.25s ease-in;
}
/*Work section end*/
/*About Section*/
.Aboutimg {
  width: 100%;
  height: 100%;
}
.Section-body p {
  font-size: 18px;
  font-family: var(--font-family-title);
}
.Section-body h4 {
  font-family: var(--font-family-title);
  font-weight: var(--font-weight-bold);
}
.Aboutbtn {
  padding: var(--button-padding);
  border: var(--button-border);
  font-size: 1.6rem;
  color: var(--button-color);
  text-decoration: var(--button-text-decoration);
  transition: all 0.3s ease-in-out;
}
.Aboutbtn:hover{
  background-color: var(--color-primary);
  color: white;
  transition: all 0.3s ease-in-out;
}
/*About Section end */
/*Blog section */
.Blogimg {
  width: 100%;
  height: 50%
}
.card-catogories {
  color: var(--color-primary);
}
.card-p {
  font-size: 16px;
}
.card-t {
  font-family: var(--font-family-title);
  font-weight: var(--font-weight-bold);
  font-size: 1.5rem;
  text-decoration: none;
  color: #551a8b;
}
.card-t:hover {
  text-decoration:underline;
  color: #551a8b;
}
/*Blog section */
/*Testomonial*/
.profile {
  width: 20%;
  height: 30%;
  border-radius: 50%;
  margin-bottom: 25px;
}
.card-quote {
  position: relative;
}
.card-quote::before {
  content: "\201C";
  position: absolute;
  top: -4.5rem;
  left: 3rem;
  color: #efeded;
  font-size: 10rem;
  z-index: -1;
}
.card-quote p {
  font-size: 18px;
}
.card-quote cite {
  color: var(--color-primary);
  font-family: var(--font-family-title);
  font-weight: var(--font-weight-bold);
}
/*Testomonial End*/
/*sectioncontact */
.section-contact{
  margin-top: 5rem;
}
.section-contact p {
  font-size: var(--font-size-medium);
  margin-bottom: 8rem;
}
.contact {
  margin-bottom: 5rem;
}
/*Section Contact end*/
/* Footer */
.address {
  color: white;

}
.Social-media {
  display: flex;
  flex-direction: row;
}
.Social-media li {
  font-size: 28px;
  padding-top: 48px;
  padding-left: 25px;
  align-content: space-around;
  list-style: none;
}
.Social-media li a {
  color: white;
}
.Social-media li a :hover {
  color: var(--color-primary);
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
address {
  color: white;
  font-style: italic;
}
hr{
    height: 5px;
    background-color: rgba(225, 225, 225, 1);
    border: none;
}
.footer-links {
  color: white;
  font-family: var(--font-family-title);
  text-decoration: none;
}
@media (max-width:780px) {
  .footer .fl {
    display: flex;
    flex-direction: row;
  }
}
/* Footer End*/
